import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchSelfDriveRefundReport } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import DateTime from "react-datetime";
import Moment from "moment";
import "react-datetime/css/react-datetime.css";
import { DateWrapper1, DateWrapper2 } from "../style";
import { RidesSection as RefundReportSection } from "../style/rides";
import { RefundReportTable } from "../components/RefundReportTable";
import { BallBeat } from "react-pure-loaders";
import { PaymentForm as RefundReportForm } from "../style";
function RefundReportContainer({
  refundReportData,
  fetchSelfDriveRefundReport,
  refundreport,
}) {
  const dispatch = useDispatch();
  var completedRides = refundreport.filter((rr) => {
    return rr.cancelled_reason === null;
  });
  // var allRides = refundreport;
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (refundreport) {
      // completedRides = refundreport.filter((rr) => {
      //   return rr.cancelled_reason === null;
      // });
      setFilterRefundReport(refundreport);
      // allRides = refundreport;
      // console.log(refundreport);
    }
    // if (refundReportData.businesses.length === 0) {
    //   fetchSelfDriveRefundReport();
    // }
  }, [refundreport]);
  // console.log(state, ridesFlag);

  const [filterRefundReport, setFilterRefundReport] = useState(refundreport);
  // console.log(filterRefundReport);

  const [dateIni, setDateIni] = useState(
    {
      startDate: Moment(new Date(), "DD-MM-YYYY")
        .subtract(1, "months")
        .format("DD-MMM-YYYY"),
      endDate: Moment(new Date(), "DD-MM-YYYY").format("DD-MMM-YYYY"),
    }
    // "Select date"
  );

  const [refundReportInput, setRefundReportInput] = useState({
    startDate: Moment(dateIni.startDate, "DD-MMM-YYYY").format(
      "YYYY-MM-DD 00:00:00"
    ),
    endDate: Moment(dateIni.endDate, "DD-MMM-YYYY").format(
      "YYYY-MM-DD 00:00:00"
    ),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (refundReportInput.startDate && refundReportInput.endDate) {
      fetchSelfDriveRefundReport(refundReportInput);
    } else {
      alert("Please check/ fill the inputs required");
      return;
    }
  };

  return (
    <>
      <GlobalStyle />

      <Layout>
        <RefundReportSection>
          <h2>Self Drive Refund Report List</h2>
          <br />
          <RefundReportForm>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "start",
                width: "fit-content",
              }}
            >
              <label>
                <DateWrapper1>
                  <DateWrapper2>
                    From Date
                    <span>
                      <DateTime
                        value={dateIni.startDate}
                        dateFormat="DD-MMM-YYYY"
                        timeFormat={false}
                        onChange={(date) => {
                          setRefundReportInput({
                            ...refundReportInput,
                            startDate: Moment(date, "DD-MM-YYYY").format(
                              "YYYY-MM-DD 00:00:00"
                            ),
                          });
                          setDateIni({
                            ...dateIni,
                            startDate: Moment(date, "DD-MM-YYYY").format(
                              "DD-MMM-YYYY"
                            ),
                          });
                        }}
                        className="dateTime"
                      />
                    </span>
                  </DateWrapper2>
                </DateWrapper1>
              </label>
              <label>
                <DateWrapper1>
                  <DateWrapper2>
                    To Date
                    <span>
                      <DateTime
                        value={dateIni.endDate}
                        dateFormat="DD-MMM-YYYY"
                        timeFormat={false}
                        onChange={(date) => {
                          setRefundReportInput({
                            ...refundReportInput,
                            endDate: Moment(date, "DD-MM-YYYY").format(
                              "YYYY-MM-DD 00:00:00"
                            ),
                          });
                          setDateIni({
                            ...dateIni,
                            endDate: Moment(date, "DD-MM-YYYY").format(
                              "DD-MMM-YYYY"
                            ),
                          });
                        }}
                        className="dateTime"
                      />
                    </span>
                  </DateWrapper2>
                </DateWrapper1>
              </label>
              <button
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  backgroundColor: "seagreen",
                  color: "white",
                  border: "none",
                  padding: ".5rem",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                Get Refund Report
              </button>
            </div>
          </RefundReportForm>
          {refundReportData.loadingrefundreport ? (
            <h3>
              Loading Refund Report{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={refundReportData.loadingrefundreport}
                />
              </span>{" "}
            </h3>
          ) : refundReportData.errorrefundreport ? (
            <>
              <h3>ERROR ⚠️ FETCHING Refund Report</h3>
              <br />
              <p style={{ color: "grey" }}>
                {refundReportData.errorrefundreport}
              </p>
              <br />
              <br />
            </>
          ) : (
            <>
              {filterRefundReport.length > 0 ? (
                <>
                  {/* <RefundReportStats
                    refundreport={refundreport}
                    vendor={refundReportData.vendor}
                  /> */}
                  <div>
                    <p
                      style={{
                        textTransform: "italic",
                        fontSize: ".7rem",
                        color: "gray",
                        marginBottom: ".5rem",
                      }}
                    >
                      Filter Refund Report
                    </p>
                    <select
                      style={{
                        height: "1.5rem",
                        fontSize: ".9rem",
                        border: "none",
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        e.target.value === "allRides" &&
                          setFilterRefundReport(refundreport);
                        e.target.value === "completedRides" &&
                          setFilterRefundReport(completedRides);
                      }}
                    >
                      <option value={"allRides"}>All Rides</option>
                      <option value={"completedRides"}>Completed Rides</option>
                    </select>
                  </div>
                  <RefundReportTable refundreport={filterRefundReport} />
                </>
              ) : (
                "No self drive refund report found ⚠️"
              )}
            </>
          )}
        </RefundReportSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    refundReportData: state.vendors,
    refundreport: state.vendors.refundreport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelfDriveRefundReport: (refundReportInput) =>
      dispatch(fetchSelfDriveRefundReport(refundReportInput)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundReportContainer);
