import React, { useState, useEffect } from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import Moment from "moment";
import { useScrollRestoration } from "gatsby";
export const RefundReportTable = ({ refundreport }) => {
  // var refundReport = allRides;
  // useEffect(() => {
  //   if (filterRefundReport) {
  //     refundReport = completedRides;
  //   }
  //   // console.log(refundReport);
  // }, [filterRefundReport]);
  // console.log(refundreport);
  const columns = [
    {
      Header: "Serial No",
      id: "row",
      filterable: false,
      // width: 25,
      accessor: (payout) => {
        var rowNum = refundreport.indexOf(payout) + 1;
        // console.log(rowNum);
        return rowNum;
      },
    },
    {
      Header: "Ride Start Date",
      accessor: (data) => {
        const d1 = Moment(data.ride_start_date).format("DD-MMM-YYYY HH:mm");
        return d1.toString();
      },
      id: 19,
    },
    {
      Header: "Order ID",
      accessor: "bid_id",
    },
    {
      Header: "Name",
      accessor: "traveler_name",
    },
    {
      Header: "Contact",
      accessor: "traveler_contact_no",
    },

    {
      Header: "Vendor",
      accessor: "vendor_name",
    },
    {
      Header: "Vendor ID",
      accessor: "live_api_booking_id",
    },
    {
      Header: "Status",
      accessor: (data) => {
        return data.cancelled_reason == null ? "Completed" : "Cancelled";
      },
      id: 1,
    },
    {
      Header: "Advance",
      accessor: (data) => (data.advance_paid ? data.advance_paid : 0),
    },
    {
      Header: "Security Deposit",
      accessor: "security_deposit",
    },
    {
      Header: "Security Dpst. Refund",

      accessor: (data) => {
        return data.cancelled_reason == null && data.security_refund > 0
          ? data.security_refund == 0
            ? ""
            : data.security_refund
          : data.security_deposit > 0 &&
              data.security_refund <= 0 &&
              data.cancelled_reason == null &&
              "⚠️ To be paid";
      },
      id: 3,
    },
    {
      Header: "Security Dpst. Refund Date",
      accessor: (data) => {
        return data.security_refund_date === "0"
          ? "NA"
          : data.security_refund_date.split(" ")[0];
      },
      id: 5,
    },
    {
      Header: "Order Cancel Refund",
      accessor: (data) => {
        return data.cancelled_reason != null &&
          data.advance_paid > 0 &&
          data.cancel_refund <= 0
          ? "⚠️ To be paid"
          : data.cancel_refund;
      },
      id: 2,
    },
    {
      Header: "Order Cancel Refund Date",
      accessor: (data) => {
        return data.cancel_refunded_date === "0"
          ? "NA"
          : data.cancel_refunded_date.split(" ")[0];
      },
      id: 10,
    },

    {
      Header: "Notes",
      accessor: (data) => {
        return data.notes != null ? (
          <p
            style={{ textDecoration: "underline" }}
            onClick={() => {
              alert("Notes \n" + data.notes);
            }}
          >
            View Notes
          </p>
        ) : (
          "No notes"
        );
      },
      id: 4,
      // show: this.state.showNotes,
    },
  ];

  // var data = React.useMemo(() => refundreport, []);

  // console.log(refundreport);
  // console.log(React.useMemo(() => refundreport, []));
  // console.log(completedRides);
  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      //   path={"/ride"}
      // pass filename to enable download .csv
      // className="-striped -highlight"
      filename={"refund_report"}
      extras={true}
      columns={columns}
      data={refundreport}
    />
  );

  return (
    <>
      <TableStyles>{refundreport ? tableRender : " "}</TableStyles>
    </>
  );
};
